import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import Cookies from 'js-cookie'
// import Vconsole from 'vconsole'
import { addInviter, addCustomer } from './biz'

// http://localhost:8080/product/detail?productId=2109938&inviterId=2886266&storeId=482626&salespersonId=92174
// http://localhost:8080/product/detail?productId=2109937&inviterId=2886268&storeId=482627&salespersonId=92177

const whites = ['/order/verify', '/clear/cache', '/error', '/upgrading', '/clear_cache']

router.beforeEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // if (!to.path.includes('upgrading')) {
  //   return {
  //     name: 'Upgrading'
  //   }
  // }
  if (typeof window.enterurl === 'undefined' || window.enterurl === '') {
    window.enterurl = location.protocol + '//' + location.host + to.fullPath
  }
  if (whites.indexOf(to.path) > -1) {
    return true
  }
  if (/micromessenger/.test(navigator.userAgent.toLowerCase())) {
    if ((typeof Cookies.get('wechatOpenid') === 'undefined')) {
      if (to.name !== 'WechatOauth') {
        return {
          name: 'WechatOauth',
          query: {
            redirect: to.fullPath
          }
        }
      } else {
        return true
      }
    }
  }

  if (typeof to.query.s !== 'undefined' && typeof to.query.storeId === 'undefined') {
    to.query.storeId = to.query.s
  }
  if (typeof to.query.storeId === 'undefined' && typeof Cookies.get('storeId') === 'undefined') {
    return { name: 'Error' }
  }
  if (typeof to.query.storeId !== 'undefined') {
    if (to.query.storeId !== Cookies.get('storeId')) {
      Cookies.remove('salespersonId')
      Cookies.remove('inviterId')
      Cookies.remove('memberId')
      Cookies.remove('staffId')
      Cookies.remove('buyerId')
      Cookies.remove('userId')
      Cookies.remove('token')
    }
    // const obj = Cookies.get()
    // for (const item of Object.keys(obj)) {
    //   Cookies.remove(item)
    // }
    Cookies.set('storeId', to.query.storeId, { expires: 1 })
  }
  // if (typeof to.query.salespersonId !== 'undefined') {
  //   addSalesperson({
  //     storeId: Cookies.get('storeId'),
  //     salespersonId: to.query.salespersonId
  //   })
  // }
  if (typeof to.query.salespersonId !== 'undefined') {
    Cookies.set('salespersonId', to.query.salespersonId, { expires: 1 })
  }
  if (typeof to.query.inviterId !== 'undefined') {
    Cookies.set('inviterId', to.query.inviterId, { expires: 1 })
  }
  if (to.meta.requireAuth && (typeof Cookies.get('token') === 'undefined')) {
    return {
      name: 'Login',
      query: {
        redirect: to.fullPath,
        role: 'BUYER'
      }
    }
  }
  if (typeof Cookies.get('inviterId') !== 'undefined' && typeof Cookies.get('buyerId') !== 'undefined') {
    addInviter({
      storeId: Cookies.get('storeId'),
      inviterId: Cookies.get('inviterId'),
      inviteeId: Cookies.get('buyerId')
    })
  }
  if (typeof Cookies.get('salespersonId') !== 'undefined' && typeof Cookies.get('buyerId') !== 'undefined') {
    addCustomer({
      buyerId: Cookies.get('buyerId'),
      staffId: Cookies.get('salespersonId')
    })
  }
  return true
})
// const vConsole = new Vconsole()
// .use(vConsole)
createApp(App).use(Vant).use(store).use(router).mount('#app')
