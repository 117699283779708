import axios from 'axios'
import Cookies from 'js-cookie'
import router from './router'
import { clearCache } from '@/util/util'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 60000
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers.Authorization = Cookies.get('token') || ''
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        // sellerId: Cookies.get('seller_id') || '',
        _t: Date.parse(new Date()) / 1000
      }
    } else if (config.method === 'post') {
      config.data = {
        ...config.data,
        // sellerId: Cookies.get('seller_id') || '',
        _t: Date.parse(new Date()) / 1000
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    switch (res.code) {
      case 403:
        clearCache({
          retain: ['storeId']
        })
        router.replace({
          name: 'Login',
          query: {
            redirect: router.currentRoute.value.fullPath,
            role: 'BUYER'
          }
          // 服务器存放cookies过期检查，若过期返回403，则清除本地cookies并调用登录页 }
        })
        break
      case 404:
        router.replace({
          path: '/maintain'
        })
        break
      default:
        return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export function post (url, data) {
  return service.post(url, data)
}

export default service
