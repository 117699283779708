import Cookies from 'js-cookie'
import { post } from './request'

export function autoBiz () {
  if (typeof Cookies.get('inviterId') !== 'undefined' && typeof Cookies.get('token') !== 'undefined' && typeof Cookies.get('storeId') !== 'undefined') {
    post('/invite.create', {
      storeId: Cookies.get('storeId'),
      inviterId: Cookies.get('inviterId'),
      inviteeId: Cookies.get('buyerId')
    }).then(res => {
      Cookies.remove('inviterId')
    })
  }
}
export function addSalesperson ({ storeId, salespersonId }) {
  post('/staff.get', {
    storeId: storeId,
    staffId: salespersonId
  }).then(res => {
    if (res.code === 0) {
      Cookies.set('salespersonId', res.data.staffId, { expires: 1 })
    } else {
      Cookies.remove('salespersonId')
    }
  })
}
export function addCustomer ({ buyerId, staffId }) {
  post('/customer.create', {
    buyerId: buyerId,
    staffId: staffId
  }).then(res => {
    Cookies.remove('salespersonId')
  })
}
export function addInviter ({ storeId, inviterId, inviteeId }) {
  post('/invite.create', {
    storeId: storeId,
    inviterId: inviterId,
    inviteeId: inviteeId
  }).then(res => {
    Cookies.remove('inviterId')
  })
}
