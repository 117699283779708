export default [
  {
    name: 'Register',
    path: '/register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  // {
  //   name: 'oclogin',
  //   path: '/oclogin',
  //   component: () => import('@/views/OcLogin.vue'),
  //   meta: {
  //     title: '登录'
  //   }
  // },
  {
    name: 'WechatOauth',
    path: '/wechatOauth',
    component: () => import('@/views/WechatOauth.vue'),
    meta: {
      title: '微信授权'
    }
  },
  {
    name: 'UpdatePassword',
    path: '/updatePassword',
    component: () => import('@/views/UpdatePassword.vue'),
    meta: {
      title: '修改密码'
    }
  },
  {
    name: 'Withdrawal',
    path: '/withdrawal',
    component: () => import('@/views/Withdrawal.vue'),
    meta: {
      title: '账户提现'
    }
  },
  {
    name: 'Fund',
    path: '/fund',
    component: () => import('@/views/Fund.vue'),
    meta: {
      title: '资金明细'
    }
  },
  {
    name: 'Commission',
    path: '/commission',
    component: () => import('@/views/Commission.vue'),
    meta: {
      title: '分润记录'
    }
  },
  {
    name: 'Logistics',
    path: '/logistics',
    component: () => import('@/views/Logistics.vue'),
    meta: {
      title: '物流'
    }
  },
  {
    name: 'ClearCache',
    path: '/clear_cache',
    component: () => import('@/views/ClearCache.vue')
  },
  {
    name: 'Error',
    path: '/error',
    component: () => import('@/views/Error.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/Error.vue')
  }
]
