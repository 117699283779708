import { createRouter, createWebHistory } from 'vue-router'
import r from '@/util/router.js'

const routes = [
  ...r,
  {
    name: 'Upgrading',
    path: '/upgrading',
    component: () => import('./views/Upgrading.vue'),
    meta: {
      title: '店铺正在升级中，稍后再试'
    }
  },
  {
    name: 'Home',
    path: '/',
    component: () => import('./views/Index.vue'),
    meta: {
      title: '主页'
    }
  },
  {
    name: 'LoginByWeiXin',
    path: '/login-by-weixin',
    component: () => import('./views/LoginByWeiXin.vue'),
    meta: {
      title: '通过微信登录'
    }
  },
  {
    name: 'ProductList',
    path: '/productList',
    component: () => import('./views/product/List.vue'),
    meta: {
      title: '商品列表'
    }
  },
  {
    name: 'ProductDetail',
    path: '/product/detail',
    component: () => import('./views/product/Detail.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    name: 'User',
    path: '/user',
    component: () => import('./views/User.vue'),
    meta: {
      title: '用户中心',
      requireAuth: true
    }
  },
  {
    name: 'Recharge',
    path: '/recharge',
    component: () => import('./views/Recharge.vue'),
    meta: {
      title: '充值'
    }
  },
  {
    name: 'OrderForm',
    path: '/orderForm',
    component: () => import('./views/order/Form.vue'),
    meta: {
      title: '提交订单',
      requireAuth: true
    }
  },
  {
    name: 'Receiver',
    path: '/receiver',
    component: () => import('./views/Receiver.vue'),
    meta: {
      title: '收件人'
    }
  },
  {
    name: 'RegisterMember',
    path: '/registerMember',
    component: () => import('./views/RegisterMember.vue'),
    meta: {
      title: '注册会员'
    }
  },
  {
    name: 'Deposit',
    path: '/deposit',
    component: () => import('./views/Deposit.vue'),
    meta: {
      title: '会员充值'
    }
  },
  {
    name: 'DepositLog',
    path: '/depositLog',
    component: () => import('./views/DepositLog.vue'),
    meta: {
      title: '充值记录'
    }
  },
  {
    name: 'Cart',
    path: '/cart',
    component: () => import('./views/Cart.vue'),
    meta: {
      title: '购物车',
      requireAuth: true
    }
  },
  {
    name: 'OrderForm',
    path: '/order/form',
    component: () => import('./views/order/Form.vue'),
    meta: {
      title: '提交订单',
      requireAuth: true
    }
  },
  {
    name: 'Receiver',
    path: '/receiver',
    component: () => import('./views/Receiver.vue'),
    meta: {
      title: '提交订单',
      requireAuth: true
    }
  },
  {
    name: 'OrderList',
    path: '/order/list',
    component: () => import('./views/order/List.vue'),
    meta: {
      title: '订单列表',
      requireAuth: true
    }
  },
  {
    name: 'OrderDetail',
    path: '/order/detail',
    component: () => import('./views/order/Detail.vue'),
    meta: {
      title: '订单详情',
      requireAuth: true
    }
  },
  {
    name: 'OrderCredential',
    path: '/order/credential',
    component: () => import('./views/order/Credential.vue'),
    meta: {
      title: '订单核销',
      requireAuth: true
    }
  },
  {
    name: 'OrderRefund',
    path: '/order/refund',
    component: () => import('./views/order/Refund.vue'),
    meta: {
      title: '订单核销',
      requireAuth: true
    }
  },
  {
    name: 'OrderReceipt',
    path: '/order/receipt',
    component: () => import('./views/order/Receipt.vue'),
    meta: {
      title: '确认收货',
      requireAuth: true
    }
  },
  {
    name: 'OrderIotStart',
    path: '/order/iot_start',
    component: () => import('./views/order/IotStart.vue'),
    meta: {
      title: '物联网启动',
      requireAuth: true
    }
  },
  {
    name: 'AddComment',
    path: '/order/addComment',
    component: () => import('./views/order/AddComment.vue'),
    meta: {
      title: '添加评价',
      requireAuth: true
    }
  },
  {
    name: 'FreePay',
    path: '/freePay',
    component: () => import('./views/FreePay.vue'),
    meta: {
      title: '店内付款',
      requireAuth: true
    }
  },
  {
    name: 'FreePayLog',
    path: '/free_pay_log',
    component: () => import('./views/FreePayLog.vue'),
    meta: {
      title: '店内付款记录',
      requireAuth: true
    }
  },
  {
    name: 'OrderVerify',
    path: '/order/verify',
    component: () => import('./views/order/Verify.vue'),
    meta: {
      title: '订单核销'
    }
  },
  {
    name: 'Invitation',
    path: '/invitation',
    component: () => import('./views/Invitation.vue'),
    meta: {
      title: '我的邀请',
      requireAuth: true
    }
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('./views/Profile.vue'),
    meta: {
      title: '设置头像',
      requireAuth: true
    }
  },
  {
    name: 'Chat',
    path: '/chat',
    component: () => import('./views/Chat.vue'),
    meta: {
      title: '在线客服'
    }
  },
  {
    name: 'ProductShare',
    path: '/product/share',
    component: () => import('./views/product/Share.vue'),
    meta: {
      title: '分享商品',
      requireAuth: true
    }
  },
  {
    name: 'ProductComment',
    path: '/product/shacommentre',
    component: () => import('./views/product/Comment.vue'),
    meta: {
      title: '商品评论'
    }
  },
  {
    name: 'TechnicianOrder',
    path: '/technicianOrder',
    component: () => import('./views/staff/TechnicianOrder.vue'),
    meta: {
      title: '预约我的'
    }
  },
  {
    name: 'SalespersonOrder',
    path: '/salespersonOrder',
    component: () => import('./views/staff/SalespersonOrder.vue'),
    meta: {
      title: '我销售的'
    }
  },
  {
    name: 'MyCustomer',
    path: '/my_customer',
    component: () => import('./views/staff/MyCustomer.vue'),
    meta: {
      title: '我的顾客'
    }
  },
  {
    name: 'FreePayQrCode',
    path: '/FreePayQrCode',
    component: () => import('./views/staff/FreePayQrCode.vue'),
    meta: {
      title: '付款二维码'
    }
  },
  {
    name: 'Outlet',
    path: '/outlet',
    component: () => import('./views/Outlet.vue'),
    meta: {
      title: '线下网点'
    }
  },
  {
    name: 'AccountWechatSettings',
    path: '/AccountWechatSettings',
    component: () => import('./views/account/WechatSettings.vue'),
    meta: {
      title: '微信设置'
    }
  },
  {
    name: 'bindMember',
    path: '/bindMember',
    component: () => import('./views/bindMember.vue'),
    meta: {
      title: '会员信息',
      requireAuth: true
    }
  },
  {
    name: 'Withdrawal',
    path: '/withdrawal',
    component: () => import('./views/Withdrawal.vue'),
    meta: {
      title: '累计收益'
    }
  },
  {
    name: 'WithdrawalOld',
    path: '/WithdrawalOld',
    component: () => import('@/views/WithdrawalOld.vue'),
    meta: {
      title: '账户提现'
    }
  },
  {
    name: 'Fund',
    path: '/fund',
    component: () => import('./views/Fund.vue'),
    meta: {
      title: '收益明细'
    }
  },
  {
    name: 'unionGoods',
    path: '/unionGoods',
    component: () => import('./views/unionProduct.vue'),
    meta: {
      title: '联盟卡商品'
    }
  },
  {
    name: 'statistics',
    path: '/statistics',
    component: () => import('./views/statistics.vue'),
    meta: {
      title: '员工数据统计'
    }
  },
  {
    name: 'Test',
    path: '/test',
    component: () => import('./views/Test.vue'),
    meta: {
      title: 'test'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
